/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, ColumnWrapper, Text, Subtitle, Image, ColumnCover, ColumnDiv, Title } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="--menu mt--50 pb--12 pt--12" style={{"left":0,"zIndex":"2","position":"absolute"}} menu={true} name={"menu"}>
          
          <Menu >
            
            <MenuButton >
            </MenuButton>

            <MenuWrapBox className="--style5 --justify">
              
              <MenuButtonClose >
              </MenuButtonClose>

              <Button className="btn-box btn-box--hvr4 btn-box--sbtn2 fs--16 w--400 swpf--uppercase" href={"#informace"} target={""} content={"&nbsp; &nbsp; O NÁS"}>
              </Button>

              <Button className="btn-box btn-box--hvr4 btn-box--sbtn2 fs--16 w--400 swpf--uppercase" href={"#sluzby"} target={""} content={"Služby"}>
              </Button>

              <Button className="btn-box btn-box--hvr4 btn-box--sbtn2 fs--16 w--400 swpf--uppercase" href={"#kontakt"} target={""} content={"Kontakt"}>
              </Button>

              <Button className="btn-box btn-box--hvr4 btn-box--sbtn2 fs--16 w--400 swpf--uppercase" href={"/galerie"} target={""} content={"galerie"}>
              </Button>

              <Button className="btn-box btn-box--hvr4 btn-box--sbtn2 fs--16 w--400 swpf--uppercase" href={"/dodavatele"} target={""} content={"dodavatelé"}>
              </Button>

              <Button className="btn-box btn-box--hvr4 btn-box--sbtn2 fs--16 w--400 swpf--uppercase" href={"/akce"} target={""} content={"akce"}>
              </Button>

              <Button className="btn-box btn-box--hvr4 btn-box--sbtn2 fs--16 w--400 swpf--uppercase" href={"/pracovni-doba"} target={""} content={"pracovní doba"}>
              </Button>

            </MenuWrapBox>

          </Menu>

        </Column>


        <Column className="css-1a5wyv4 --border2 bg--top --full pb--30 pt--30" name={"uvod"} border={"2"} parallax={false} fullscreen={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/12874/7f82cf684d7f4f45b42b11f6233d8b3c_bl=3_s=350x_.png);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/12874/7f82cf684d7f4f45b42b11f6233d8b3c_bl=3_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/12874/7f82cf684d7f4f45b42b11f6233d8b3c_bl=3_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/12874/7f82cf684d7f4f45b42b11f6233d8b3c_bl=3_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/12874/7f82cf684d7f4f45b42b11f6233d8b3c_bl=3_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/12874/7f82cf684d7f4f45b42b11f6233d8b3c_bl=3_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/12874/7f82cf684d7f4f45b42b11f6233d8b3c_bl=3_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/12874/7f82cf684d7f4f45b42b11f6233d8b3c_bl=3_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="--center el--1 pb--30 flex--bottom" style={{"paddingBottom":null}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box ff--2 fs--72 text-box--invert swpf--uppercase" content={"Podlahářství prestige"}>
              </Text>

              <Text className="text-box ff--2 fs--20 text-box--invert ls--12 mt--08" content={"=ROZUMÍME PODLAHÁM=<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"informace"}>
          
          <ColumnWrap className="column__flex --center el--4" style={{"maxWidth":1610}} columns={"4"}>
            
            <ColumnWrapper style={{"maxWidth":350}}>
              
              <Subtitle className="subtitle-box" style={{"maxWidth":508}} content={"Mnohaleté zkušenosti"}>
              </Subtitle>

              <Text className="text-box" content={"Naše práce je prověřena více než 15 lety práce v oboru doma i ve světě."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Komplexní služby"}>
              </Subtitle>

              <Text className="text-box" content={"Odborná konzultace, zaměření prostoru, tvorba návrhu i samotná realizace - vše s ohledem na přání zákazníka a s citem pro detail."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Všestrannost"}>
              </Subtitle>

              <Text className="text-box" content={"Chcete podlahu do bytu, domu nebo třeba pracovních prostor? Není problém, u nás si vybere každý.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Jsme tu pro Vás"}>
              </Subtitle>

              <Text className="text-box" content={"Ať už přesně víte co chcete, nejste si ještě zcela jistí nebo se chcete jen podívat, přijďte nás navštívit a vše potřebné spolu probereme.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"sluzby"} layout={"l30"}>
          
          <ColumnWrap className="column__flex --center el--3" style={{"gridGap":"0.5em","maxWidth":1060}} columns={"3"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":458}} src={"https://cdn.swbpg.com/t/12874/5dde3185bfb9404f94198f34e11839c1_s=660x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/12874/5dde3185bfb9404f94198f34e11839c1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/12874/5dde3185bfb9404f94198f34e11839c1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/12874/5dde3185bfb9404f94198f34e11839c1_s=860x_.jpg 860w"} position={{"x":"-59.23566878980896%","y":"-1.5283842794759828%"}}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Button className="btn-box btn-box--hvr5 btn-box--shape3 btn-box--shadow5 btn-box--filling4 fs--20" href={"#sluzby"} content={"Pokládka<br>"}>
                    </Button>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":360}} src={"https://cdn.swbpg.com/o/12874/10e26f74a6ff4feea1191a15d2612cbe.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Button className="btn-box btn-box--hvr5 btn-box--pbtn4 btn-box--shape3 btn-box--shadow5 btn-box--filling4 fs--20" href={"#sluzby"} content={"Údržba<br>"}>
                    </Button>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":360}} src={"https://cdn.swbpg.com/t/12874/d58ed123cf4f4b52b711ee64bdebd94d_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/12874/d58ed123cf4f4b52b711ee64bdebd94d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/12874/d58ed123cf4f4b52b711ee64bdebd94d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/12874/d58ed123cf4f4b52b711ee64bdebd94d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/12874/d58ed123cf4f4b52b711ee64bdebd94d_s=1400x_.jpg 1400w"}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Button className="btn-box btn-box--hvr5 btn-box--cbtn1 btn-box--shape3 btn-box--shadow5 btn-box--filling4 fs--20" href={"#sluzby"} content={"Prodej"}>
                    </Button>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":null,"paddingBottom":null}} name={"kontakt"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--2 mt--20 pb--60 pt--60 flex--center" style={{"border":"1em solid var(--color-main-3)","maxWidth":"","marginTop":null,"paddingTop":null,"paddingLeft":null,"paddingBottom":null}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box swpf--uppercase" content={"Kontakt"}>
              </Title>

              <Text className="text-box fs--24" style={{"maxWidth":500}} content={"<span style=\"background-color: rgb(255, 255, 255); color: rgb(0, 0, 0);\">Naše podlahářství najdete v malebné obci Koclířov, nedaleko Svitav.<br></span><br><span style=\"font-style: italic;\">IČO: 07941927<br></span>DIČ: CZ07941927<br>Sp. zn. C 43443 vedená u Krajského soudu v&nbsp; Hradci Králové<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box ff--2" content={"+420 776 458 444<br>+420 733 267 272<br>"}>
              </Subtitle>

              <Subtitle className="subtitle-box mt--02" style={{"marginTop":null}} content={"podlaharstviprestige@seznam.cz"}>
              </Subtitle>

              <Subtitle className="subtitle-box mt--02" style={{"marginTop":null}} content={"Koclířov 97, 569 11"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1k7spiu --border2 bg--right bg--bottom --parallax pb--50 pt--50" name={"paticka"} border={"2"} layout={"l1"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/12874/207af5daeb714735b814ebe626b4d804_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/12874/207af5daeb714735b814ebe626b4d804_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/12874/207af5daeb714735b814ebe626b4d804_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/12874/207af5daeb714735b814ebe626b4d804_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/12874/207af5daeb714735b814ebe626b4d804_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/12874/207af5daeb714735b814ebe626b4d804_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/12874/207af5daeb714735b814ebe626b4d804_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/12874/207af5daeb714735b814ebe626b4d804_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--72 title-box--invert swpf--uppercase" content={"Prestige"}>
              </Title>

              <Text className="text-box fs--16 text-box--invert ls--12 mt--08" content={"Těšíme se na Vás"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--20" style={{"paddingTop":null}} name={"paticka-2"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box" style={{"maxWidth":462}} content={"<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}